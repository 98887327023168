var TextMultiReplace = function(){
	console.log("loading TextMultiReplace");
	this.replace_table = [];
	this.source_txt ="";
	this.init = function(){
		
	}
	this.init();
	this.set_replace_table = function(arr){
		this.replace_table = arr;
	}
	this.replace_text = function(text){
		this.source_txt = text
		return this.start_replace();
	}
	this.start_replace = function(){
		console.log("start_replace");
		console.log(this.source_txt );
		console.log(this.replace_table );
		var str_arr = [];
		str_arr.push( this.source_txt );
		for(i=0;i<this.replace_table.length;i++){
			for(j=0;j<str_arr.length;j++){
				str_arr = this.array_dig(str_arr, this.dig_split, this.replace_table[i][0] );

			}

		}
		console.log(str_arr );
		for (var i = this.replace_table.length - 1; i >= 0 ; i--){
			str_arr = this.array_join_bottom(str_arr,(i+1),this.replace_table[i][1])
		}
		console.log(str_arr );
		return str_arr+"";
	}
	this.dig_split = function(text,search_str){
		return text.split(search_str);
	}
	this.array_dig = function(nest_array,bottom_function,option_arr){
		var i=0;
		var fn_return_arg = undefined;
		for(i=0;i<nest_array.length;i++){
			if( Array.isArray(nest_array[i]) ){
				this.array_dig(nest_array[i],bottom_function,option_arr);
			}else{
				fn_return_arg = bottom_function(nest_array[i],option_arr);
				if(fn_return_arg !== undefined){
					nest_array[i] = fn_return_arg;
					fn_return_arg = undefined;
				}
			}
			
		}
		return nest_array;
	}
	this.array_join_bottom = function(nest_array,nest_depth,join_str){
		var i = 0;
		var depth = nest_depth;
		if(depth>0){
			depth = depth -1;
			var return_arr = []
			for(i=0; i< nest_array.length;i++){
				 return_arr.push( this.array_join_bottom(nest_array[i],depth,join_str) );
			}
			return return_arr;
		}else{
			return nest_array.join(join_str);
		}
	}
}
window.TextMultiReplace = TextMultiReplace;