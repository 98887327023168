var AA = function(){
    this.check = function(text){
    	var check_arr = [" ﾟ∀ﾟ"," ﾟ ∀ ﾟ","　 　",":::::"," 'ω'"," ﾟ ヮﾟ","‘ω‘","・ω・","'''''"];
    	var check = false;

    	check = text.indexOf("　　") == 0 ? true : check;
    	check = text.indexOf(". ") == 0 ? true : check;
    	check = text.split("\n").length > 12 ? true : check;
    	if(!check){
    		var i=0;
    		for(i=0;i<check_arr.length;i++){
    			if(text.indexOf(check_arr[i]) > 0){
    				check = true;
    				break;
    			}
    		}
    	}
    	return check;
    }
    this.displayAA_all = function(){
    	var aa_elms = document.getElementsByClassName("aa");
    	var  i =0;
    	for(i=0; i<aa_elms.length;i++){
    		this.displayAA(aa_elms[i])
    	}
    }

    this.displayAA = function (obj) {
    	var c_w = obj.clientWidth
    	var r_w = obj.scrollWidth
    	if(c_w<r_w){
    		scale = c_w / r_w
    		obj.style.transform = "scale("+scale+")"
    	}else{
    		scale =1
    		obj.style.transform = ""
    	}
    	obj.style.height = (obj.clientHeight * scale) + "px"
    }
}
window.AA = AA;