const channels = require.context('.', true)
channels.keys().forEach(channels)

function test(){
    alert('vv');
}

window.test = test;



