var TagInputHelper = function(var_name,result_input_id){
	console.log("loading TagInputHelper");
	this.var_name = var_name;
	this.result_input_id =result_input_id;

	this.obj_result_input ="";
	this.obj_add_input = null;
	this.obj_taglist = null;
	this.obj_ui_container = null;
	this.tag_arr = [];


	this.tag_template = {
			"配信ポリシー" : ["和気あいあい","コメント大事に","悠々自適","アドバイス歓迎","居酒屋クオリティ","黙々と作業","イベント配信"],
			"ジャンル" : ["FPS","TPS","MMO","MOBA","RTS","TCG","サンドボックス","音ゲー","イラスト","マンガ","手芸工作","演奏","開発","雑談"],

				};

	this.open = function(){
		this.obj.style.display = "block";
	}
	this.close = function(){
		this.obj.style.display = "none";
	}
	this.init = function(){
		this.obj_result_input = document.getElementById(this.result_input_id);
		this.set_ui();
	}
	this.set_ui = function(){

		this.obj_ui_container  = document.createElement("div");
		this.obj_ui_container.id = "tih_" + var_name + "_container";
		this.obj_result_input.parentNode.insertBefore(this.obj_ui_container, this.obj_result_input.nextSibling);

		this.obj_taglist = document.createElement("ul");
		this.obj_taglist.classList.add("taglist")
		this.obj_ui_container.appendChild(this.obj_taglist);
		this.tag_arr = this.obj_result_input.value.split(' , ').filter(Boolean);
		this.apply_tag_array_to_listtag();

		this.obj_add_input = document.createElement("input");
		this.obj_add_input.style="margin-left:5px;"
		this.obj_add_input.size = 4;
		this.obj_add_input.placeholder ="タグ名";
		this.obj_ui_container.appendChild(this.obj_add_input);

		var span = document.createElement("span");
		span.innerHTML = '<input type="button" onclick="'+ var_name +'.adding_tag()" value="追加" />';
		this.obj_ui_container.appendChild(span);
		this.set_tag_template_list();

	}
	this.set_tag_template_list = function(){

		var template_contaier = document.createElement("dl");
		    template_contaier.classList.add("tag_template_list");
		    this.obj_ui_container.appendChild(template_contaier);
		
		for(key in this.tag_template){
			var category = document.createElement("dt");
			    category.innerHTML =""+ key +"";
			    template_contaier.appendChild(category);
			var dt_list = document.createElement("dd");
			    template_contaier.appendChild(dt_list);

			var ul = document.createElement("ul");
			    ul.classList.add("taglist")
			    dt_list.appendChild(ul);
			for(i=0;i<this.tag_template[key].length;i++){
		/*		
				input = document.createElement("input");
				input.type ="button";
				input.value = tag_template[key][i];
				input.onclick= "test_tag.adding_tag('"+  tag_template[key][i] +"')";
		*/
				a = document.createElement("a");
				a.innerHTML = "［"+this.tag_template[key][i]+"］";
				a.href= "javascript:"+ this.var_name +".add('"+  this.tag_template[key][i] +"')";

				li = document.createElement("li");
				ul.appendChild(li);
				li.appendChild(a);
			}
		}
	}

	this.adding_tag = function(){
	  new_tag = this.obj_add_input.value;
	  this.add(new_tag)
	  this.obj_add_input .value = "";
	}

	this.add = function(new_tag){
	  new_tag = new_tag.trim()
	  if(this.tag_arr.indexOf(new_tag) >= 0 || new_tag == "" || new_tag == null){

	  }else{
	    this.tag_arr.push(new_tag);
	    this.apply_tag_array_to_input();
	    this.apply_tag_array_to_listtag();
	  }
	}
	this.removing_tag = function(tagname){
	    this.tag_arr.splice(this.tag_arr.indexOf(tagname), 1);
	    this.apply_tag_array_to_input();
	    this.apply_tag_array_to_listtag();
	}

	this.apply_tag_array_to_input = function(){
	  this.obj_result_input.value = this.tag_arr.join(' , ');
	};
	this.apply_tag_array_to_listtag = function(){
	  insert_tag = "";
	  for(i=0;i<this.tag_arr.length;i++){
	    insert_tag += "<li><span>" + this.tag_arr[i] + " <a style="+'"display:inline;"'+" href=" +'"javascript:'+ this.var_name +'.removing_tag(' + "'" + this.tag_arr[i] + "'" + ');"' + ">[x]</a></span></li> ";
	  }
	  this.obj_taglist.innerHTML = insert_tag;
	};


	this.init();
}
window.TagInputHelper = TagInputHelper;