var TextVoiceReader = function(var_name,util_container_id){
	console.log("loading TextVoiceReader");
	this.var_name = var_name;
	this.util_container_id = util_container_id;
	this.volume_id = util_container_id +"__volume";
	this.rate_id = util_container_id +"__rate";
	this.pitch_id = util_container_id +"__pitch";
	this.max_id = util_container_id +"__max";
	this.base_tag = "";
	this.select_voice_id = var_name + "_select_voice";
	this.obj_util_container = null;
	this.voice_param = {
				volume:1,
				rate:1,
				pitch:1,
				voice_no:null,
			}
	this.voices = [];
	this.default_voice_no = null;
	this.skip_url_flag =true;
	this.lang = "ja-JP"

	this.reading_text = "あいさつ";

	this.g_util_tag = function(){

		var htmltag = "";
		htmltag  = '<select id="'+ this.select_voice_id +'" name="voice_no" onchange="'+ this.var_name +'.update_param(this)"></select>';
		htmltag += '<table>';
		htmltag += '<tr><td>声の音量</td>			<td><input type="range" name="volume" 	id="'+ this.volume_id +'" min="0" max="1" step="0.1" value="1" 	onchange="'+ this.var_name +'.update_param(this)"></td></tr>';
		htmltag += '<tr><td>声の速度</td>		<td><input type="range" name="rate" 		id="'+ this.rate_id +'" min="0.25" max="3.5" step="0.25" value="1" onchange="'+ this.var_name +'.update_param(this)"></td></tr>';
		htmltag += '<tr><td>声の高さ</td>		<td><input type="range" name="pitch" 		id="'+ this.pitch_id +'" min="0" max="2" step="0.1" value="1" 	onchange="'+ this.var_name +'.update_param(this)"></td></tr>';
		htmltag += '<tr><td>最大読み上げ文字数</td>	<td><input type="number" name="max" 		id="'+ this.max_id +'" min="0" value="80" size="3"	onchange="'+ this.var_name +'.update_param(this)"></td></tr>';
		htmltag += '</table>';
		return htmltag;
	}
	this.update_param = function(input_obj){
		this.voice_param[ input_obj.name ] = input_obj.value;
		localStorage.setItem("voice_param", JSON.stringify(this.voice_param) );
	}
	this.set_param = function(hash){
		this.voice_param = {
			volume:hash.volume,
			rate:hash.rate,
			pitch:hash.pitch,
			voice_no:hash.voice_no,
			max:hash.max,
		};
		document.getElementById(this.volume_id).value = this.voice_param.volume ;
		document.getElementById(this.rate_id).value = this.voice_param.rate ;
		document.getElementById(this.pitch_id).value = this.voice_param.pitch ;
		document.getElementById(this.select_voice_id ).value = (this.voice_param.voice_no !== null ? this.voice_param.voice_no : this.default_voice_no ) ;
		document.getElementById(this.max_id ).value = this.voice_param.max ;
		localStorage.setItem("voice_param", JSON.stringify(this.voice_param) );
	}
	this.reset_param = function(){
		voice_param = {
				volume:1,
				rate:1,
				pitch:1,
				voice_no:this.default_voice_no,
				max:80,
		}
		this.set_param(voice_param);
	}
	
	this.set_text = function(text){
		this.reading_text = text;
	}
	this.start_read = function(){
		this.speak(this.reading_text)
	}
	this.speak = function(text){
		if( !this.can_speak ){
			alert("このブラウザはSpeechAPIに対応していません。");
			return false;
		}
		if(this.skip_url_flag){
			text = this.skip_url(text);
		}
		if(this.voice_param.max < text.length ){
			text = text.substr(0,this.voice_param.max) + " 以下省略";
		}
		var utter = new SpeechSynthesisUtterance();
		utter.text = text;
		utter.lang = 'ja-JP';
		utter.rate = this.voice_param.rate;
		utter.pitch = this.voice_param.pitch;
		utter.volume = this.voice_param.volume;
		utter.voice = this.voices[ this.voice_param.voice_no ];
		window.speechSynthesis.speak(utter);
	}
	this.cancel = function(){
		window.speechSynthesis.cancel();
	}

	this.init = function(){
		this.obj_util_container = document.getElementById(this.util_container_id);
		this.obj_util_container.innerHTML = this.g_util_tag();
		this.speach_support_check();
		if(this.can_speak){
			this.load_voice();
		}else{
		}
		saved_param = JSON.parse(localStorage.getItem("voice_param"));
		console.log(saved_param);
		if( saved_param ){
			this.set_param(saved_param);
		}

	}
	this.speach_support_check = function(){
		if (typeof SpeechSynthesisUtterance == "undefined") {
			this.can_speak = false;
			return false;
		}else{
			this.can_speak = true;
			return true;
		}
	}
	this.skip_url = function(str){
		console.log("skip_url");
		console.log(str);
		var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
		var regexp_makeLink = function(all, url, h, href) {
		return ' URL省略 ';
		}

		return str.replace(regexp_url, regexp_makeLink);

	}
	this.load_voice = function(){
		this.voices = window.speechSynthesis.getVoices();
		console.log(this.voices);
		document.getElementById( this.select_voice_id ).innerHTML="";
		for(i=0; i< this.voices.length; i++){
			var option = document.createElement("option");
			option.value = i
			option.text = this.voices[i]["name"]
			if( this.voices[i]["default"] && this.voices[i].lang == "ja-JP"){
				this.default_voice_no = i ;
			}
			if( (this.voices[i]["default"] && this.voice_param.voice_no === null && this.voices[i].lang == "ja-JP") || this.voice_param.voice_no == i ){
				option.selected = true;
				this.voice_param.voice_no = i;
			}
			document.getElementById( this.select_voice_id ).appendChild(option);

		}
	}

	this.init();
}
window.TextVoiceReader = TextVoiceReader;
