var ToolTip = function(){
	this.tiptool_obj =null;
	this.open = function(obj,content){
		if(this.tiptool_obj){
			this.tiptool_obj.style.display = "block";
		}else{
			this.tiptool_obj = document.createElement("div");

			this.tiptool_obj.style.position = "absolute";
			this.tiptool_obj.style.zIndex = "100";
			this.tiptool_obj.style.backgroundColor = "white";
			this.tiptool_obj.style.border = "1px solid black";
			this.tiptool_obj.style.fontWeight = "normal";
			this.tiptool_obj.style.padding = "5px";
			this.tiptool_obj.style.whiteSpace = "nowrap "
			document.body.appendChild(this.tiptool_obj);  
		}
		client_pos = obj.getBoundingClientRect() ;
		this.tiptool_obj.innerHTML = content;
		this.tiptool_obj.style.left = client_pos.left + "px";
//		this.tiptool_obj.style.top =    client_pos.top + window.pageYOffset + "px";
		this.tiptool_obj.style.bottom = (window.innerHeight  - client_pos.bottom - window.pageYOffset + 20 ) + "px";

	}
	this.close = function(obj){
			this.tiptool_obj.style.display = "none";
	}
}
window.ToolTip = ToolTip;