// modal window class

var ModalWindow = function(var_name){
	console.log("loading");
	this.var_name = var_name;
	this.base_tag = "";
	this.obj = "";
	this.obj_content = "";
	this.content ="";
	this.open = function(){
		this.obj.style.display = "block";
	}
	this.close = function(){
		this.obj.style.display = "none";
	}
	this.init = function(){

		var newStyle = document.createElement('style');
		newStyle.type = 'text/css';
		newStyle.innerText = '#app_modal{  z-index:500;  display:none; position:absolute;  top:0;  left:0;  color:black;  width:100%;  height:100%;  padding:0;  margin:0;  }    #app_modal_closebutton{  position:absolute;  top:-50px;  right:0px;  clear:both;  text-align:center;  box-sizing: border-box;  z-index:11;  }  #app_modal_closebutton a{  display:block;  color:white;  text-decoration:none;  background-color:black;  padding:10px;  box-sizing: border-box;  border:3px solid white;  border-radius: 10px;  }  #app_modal_content_container{  position:absolute;  box-sizing:border-box;	width:80%;  height:60%;  top:20%;  left:50%;  margin-left:-40%;  max-height:100%;  position:absolute;  z-index:10;  }  #app_modal_content{  box-sizing:border-box;  width:100%;  height:100%;  overflow-y:scroll;  padding:10px;  background-color:rgba( 255, 255, 255, 0.9 );  }  #app_modal_content p{  line-height:150%;  }  #app_modal_content>div>p>a{  display:inline;  padding:0;  margin:0;  }  #app_modal_bg{  display:block;  position:absolute;  top:0px;  left:0px;  width:100%;  height:100%;  z-index:1;  background-color:rgba( 0, 0, 0, 0.5 );  }';

		document.body.insertBefore(newStyle, document.body.firstElementChild);

		this.obj = document.createElement("div");
		
		var m_height = window.innerHeight;
		this.obj.innerHTML = '<div id="app_modal_content_container"><div id="app_modal_content"></div>	<p id="app_modal_closebutton" style=""><a href="javascript:void(0);" onclick="'+ this.var_name +'.close()">閉じる</a></p></div><div id="app_modal_bg"  onclick="'+ this.var_name +'.close()"></div>';
		this.obj.id = "app_modal";
		this.obj.style.height = m_height + "px";
		document.body.insertBefore(this.obj, document.body.firstElementChild);
		this.obj_content = document.getElementById("app_modal_content");
	}
	this.set_content = function(html_tag){
		this.obj_content.innerHTML= html_tag;
	}
	this.set_open = function(html_tag){
		this.set_content(html_tag);
		this.open();
	}
	this.init();
}
window.ModalWindow = ModalWindow;