var TextExpand = function(){
	var self = this;
	this.finder_class = "textexpand";
	this.apply = function(){
					var elems = document.getElementsByClassName( this.finder_class  );
					console.log(elems.length);
					for(i=0;i<elems.length;i++){
						if (elems[i].offsetHeight > elems[i].dataset.defheight){
							elems[i].style.overflow="hidden";
							elems[i].style.height= elems[i].dataset.defheight +"px";
							elems[i].style.position= "relative";

							var fn_button = document.createElement("input");
							fn_button.value ="全表示";
							fn_button.type = "button";
							fn_button.style.position = "absolute";
							fn_button.style.bottom = "0px";
							fn_button.style.left = "0px";
							fn_button.style.width = "100%";
							fn_button.style.border = "0";
							fn_button.style.backgroundColor = "rgb(0,0,0,0.6)";
							fn_button.style.color = "white";
							fn_button.onclick = function(){self.expand(this)};

							elems[i].appendChild(fn_button, elems[i].firstChild);  
						}



					}
	};

	this.expand = function(obj){
					var parent = obj.parentNode;
					parent.style.height = "auto";
					obj.onclick = function(){self.contract(this)};
					obj.style.position = "relative";
					obj.value = "格納";
	}
	this.contract = function(obj){
					var parent = obj.parentNode;
					console.log(parent.dataset.defheight);
					parent.style.height= parent.dataset.defheight +"px";
					obj.onclick = function(){self.expand(this)};
					obj.style.position = "absolute";
					obj.value = "全表示";
	}
}
window.TextExpand = TextExpand;
