var ImageTrimer = function(target_id,options){
	var self = this;
	this.obj_container =  document.getElementById(target_id);

	this.base_size = [100,100];
	this.trim_size = [50,50];


	this.base_container = null
	this.trimguide = null;
	this.recieved_img = null;

	this.base_canvas = null;
	this.result_ctx = null;

	this.tmp_canvas = null;
	this.tmp_ctx = null;

	this.result_canvas = null;
	this.result_ctx = null;

	this.backgoundcolor = null

	this.clear = function(){
		this.base_ctx.clearRect(0, 0, this.base_canvas.width, this.base_canvas.height)
	}
	this.output = function(){
		img02 = this.base_ctx.getImageData(this.trimguide.offsetLeft, this.trimguide.offsetTop, this.result_canvas.width, this.result_canvas.height)
		this.tmp_ctx.putImageData(img02, 0, 0);
		if(this.backgoundcolor){
			this.result_ctx.fillStyle = this.backgoundcolor;
			this.result_ctx.fillRect(0, 0, this.result_canvas.width, this.result_canvas.width) 
		}
		this.result_ctx.drawImage(this.tmp_canvas, 0, 0);

	}
	this.scale_img = function(scale){
		this.clear()
		img_width = this.recieved_img.naturalWidth;
		img_height = this.recieved_img.naturalHeight;
		img_rate = img_width / img_height;

		if(img_width > this.result_canvas.width || img_height > this.result_canvas.height ){
			if(img_width > img_height){
				base_height = this.result_canvas.height;
				base_width  = this.result_canvas.height * img_width/img_height;
			}else{
				base_width  = this.result_canvas.width;
				base_height = this.result_canvas.width * img_height/img_width;
			}
		}else{
			base_width = img_width;
			base_height = img_height;
		}
		base_width = base_width * scale;
		base_height = base_height * scale;
		
		set_pos_x = (this.base_canvas.width - base_width) /2;
		set_pos_y = (this.base_canvas.height - base_height) /2;

		this.base_ctx.drawImage(this.recieved_img, 0,0 , img_width,img_height , set_pos_x,set_pos_y , base_width,base_height);
	}
	this.set_src = function(src){
		self.recieved_img.src = src;
	}
	this.recieve_img = function(obj_file_input){
		var reader = new FileReader();

		var file = obj_file_input.files;
		fileTypes = obj_file_input.value.split(".");
		var fileType = fileTypes[fileTypes.length - 1].toLowerCase();


		if(fileType == "png" || fileType == "jpg" || fileType == "jpeg" || fileType == "gif"){
			//dataURL形式でファイルを読み込む
			reader.readAsDataURL(file[0]);

			reader.onload = function(){
				var dataUrl = reader.result;
				self.recieved_img.src = dataUrl;
			
			//context.clearRect(0,0,500,500);
			}
		}else{
			alert("png jpg  gif file only");
		}
	}
	this.fit_img_to_canvas = function(){
		this.scale_img(1);
	}
	this.init = function(hash){
		this.base_size = hash.base_size || this.base_size;
		this.trime_size = hash.trime_size || this.trime_size;

		this.backgoundcolor = hash.backgoundcolor || this.backgoundcolor;

		this.base_container = document.createElement("div");
		this.base_container.style.width = this.base_size[0]+"px";
		this.base_container.style.height = this.base_size[1]+"px";
		this.base_container.style.position = "relative";
		this.obj_container.appendChild( this.base_container );

		this.base_canvas = document.createElement("canvas");
		this.base_canvas.width = this.base_size[0];
		this.base_canvas.height = this.base_size[1];
		this.base_canvas.classList.add("pattern");
		this.base_ctx = this.base_canvas.getContext('2d');
		this.base_container.appendChild( this.base_canvas );

		this.result_canvas = document.createElement("canvas");
		this.result_canvas.width = this.trime_size[0];
		this.result_canvas.height = this.trime_size[1];
		this.result_canvas.style.display = "none";
		this.result_ctx = this.result_canvas.getContext('2d');
		this.base_container.appendChild( this.result_canvas );

		this.tmp_canvas = document.createElement("canvas");
		this.tmp_canvas.width = this.trime_size[0];
		this.tmp_canvas.height = this.trime_size[1];
		this.tmp_canvas.style.display = "none";
		this.tmp_ctx = this.tmp_canvas.getContext('2d');
		this.base_container.appendChild( this.result_canvas );


		this.recieved_img = document.createElement("img");
		this.recieved_img.style.display = "none";
		this.obj_container.appendChild( this.recieved_img );

		this.recieved_img.addEventListener('load', function() {
			console.log("onload");
			self.fit_img_to_canvas();
			self.output()
		})

		this.trimguide = document.createElement("div");

		this.trimguide.style = "position:absolute;width:"+ this.trime_size[0] +"px;height:"+ this.trime_size[1] +"px;top:"+ ((this.base_size[1] - this.trime_size[1]) /2) +"px;left:"+ ((this.base_size[0] - this.trime_size[0]) /2) +"px;box-sizing:border-box;border:2px solid red;";
		this.base_container.appendChild( this.trimguide );

	}
	this.init(options)
}
window.ImageTrimer = ImageTrimer;