var LocalStorageArray = function(localStorage_key){
	this.arr = [];
	this.localStorage_key = localStorage_key;
	this.add_fav_user = function(highchat_user_id){
		this.arr.push(highchat_user_id);
		this.uniq();
		this.save_localStorage();
	};
	this.remove_fav_user = function(highchat_user_id){
		this.uniq();
		var pos = this.arr.indexOf(highchat_user_id);
		if(pos >= 0){
			this.arr.splice(pos, 1); 
		}
		this.save_localStorage();
	};
	this.save_localStorage = function(){
		localStorage.setItem(this.localStorage_key, JSON.stringify(this.arr) );
		console.log(this.arr);
	};
	this.load_localStorage = function(){
		this.arr = JSON.parse(localStorage.getItem(this.localStorage_key));
		this.valid_array();
		console.log(this.arr);
	};
	this.uniq = function(){
		this.arr = this.arr.filter(function (x, i, self) {
		  return self.indexOf(x) === i;
		});
	};
	this.valid_array = function(arr){
		if(this.arr == null){
			this.arr = [];
		}else{
			this.arr = this.arr.filter(function (value, index, self) {
				if(value == Number(value)){
					return value;
				}
			});
		}
	};
	this.init = function(){
		this.load_localStorage();
	};
	this.init();
}
window.LocalStorageArray = LocalStorageArray