
var EmojiInputHelper = function(option){
	var self = this;
	this.callback_fn = null;

	console.log("loading EmojiInputHelper");
	this.obj_container = null;
	this.obj_target_input = null;
	this.obj_list_container = null;
	this.unicode_emoji_json = null;
	this.list = {
			streamer:null,
			global:null,
			unicode:null,
			}
	this.custom_emoji = {
				global:[],
				streamer:[]
				};


	this.set_unicode_emoji = function(json){
		this.unicode_emoji_json = json;
	}
	this.set_custom_emoji = function(json){
		
		for(var i=0;i<json.length;i++){
			if(json[i].highchat_user_id){
				this.custom_emoji.streamer.push(json[i])
			}else{
				this.custom_emoji.global.push(json[i])
			}
		}
	}
	this.g_menu = function(){
		var nav = document.createElement("nav");
		this.obj_container.appendChild(nav);

		for( var key in this.list){
			var a = document.createElement("a");
			a.href="javascript:void(0);"
			a.innerHTML = key;
			a.dataset.listname =key;
			a.onclick = function(e){
					console.log(this.dataset.listname);
					self.change_list(this.dataset.listname)
				}
			nav.appendChild(a);
		}
	}
	this.change_list = function(listname){
		for( var key in this.list){
			this.list[key].style.display = "none"
		}
		this.list[listname].style.display = "block"
	}
	this.g_input_list = function(){

		for(var i=0;i<this.unicode_emoji_json.length;i++){
			for(var j=0;j<this.unicode_emoji_json[i].emoji.length;j++){
				var li = this.g_input_li(this.unicode_emoji_json[i].emoji[j], this.unicode_emoji_json[i].emoji[j])
				this.list.unicode.appendChild(li);
			}
		}
		for(var key in this.custom_emoji){
			for(var i=0;i<this.custom_emoji[key].length;i++){
				innerHTML = '<img src="'+ this.custom_emoji[key][i].img_dir +'" alt="'+ this.custom_emoji[key][i].name +'">';
				var li = this.g_input_li(":"+this.custom_emoji[key][i].code+":", innerHTML)
				this.list[key].appendChild(li);
			}
		}
		if( this.custom_emoji.streamer.length ){
		}else{
			this.list['streamer'].innerHTML = "<small>オリジナル絵文字未登録</small>";
			this.change_list('global');
		}
	}
	this.g_input_li = function(emojicode,innerHTML){
		var li = document.createElement("li");
		var a = document.createElement("a");
		a.href="javascript:void(0);"
		a.dataset.emojicode = emojicode;
		a.innerHTML = innerHTML
		a.onclick = function(e){
				console.log(this.dataset.emojicode);
				self.obj_target_input.value += this.dataset.emojicode;
				self.callback_fn()
			}
		li.appendChild(a);
		return li;
	}
	this.ini = function(){
		this.obj_container = option.obj_container
		this.obj_target_input = option.obj_target_input
		this.callback_fn = option.callback

		this.obj_list_container = document.createElement("div");
		this.obj_container.appendChild(this.obj_list_container);

		for( var key in this.list){
			this.list[key] = document.createElement("ul");
			this.obj_list_container.appendChild(this.list[key]);
		}
		this.g_menu();

	}
	this.ini()
}
window.EmojiInputHelper = EmojiInputHelper;